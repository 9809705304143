import React from "react";
import "../../App.css";
import logo from "../../img/logo.jpg";

const About = () => (
  <section  id="about">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 mx-auto text-center">
          <h2 className="section-heading">About</h2>
          <br/>
          <div className="row">           
              <p className="mb-4 introtxt">Hi, my name is Arthur Kyupelyan. I am a computer/software engineer based in Los Angeles, CA. I enjoy working on web-based and embedded systems development.
                I also enjoy learning and experiencing new technologies and projects. I have experience in multiple large scale projects, and have learned the ability to troubleshoot and solve problems in an efficient manner.</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 mx-auto">
            <h2 className="">Education:</h2>
        </div>
        <div className="row">
          <div className="d-flex flex-column flex-md-row mb-5">
            <div className="edu-info mr-auto">
              <h4 className="">University of California, Irvine</h4>
              <p className="">B.S. in Computer Engineering</p>
            </div>  
            <div className="edu-date text-md-right">
              <span>2016-2020</span>
            </div>          
          </div>          
        </div>
      </div>
      <div className="row">
          <div className="col-lg-12 mx-auto">
            <h2 className="">Experience:</h2>
          </div>
          <div className="row">
            <div className="d-flex flex-column flex-md-row mb-5">
                <div className="exp-info mr-auto">
                    <h4>Associate Software Engineer</h4>
                    <h3>Phase Margin Inc.</h3>
                    <p>I worked alongside a team of software engineers to develop high-end products for various customers. 
                      The main project I worked on was a security system that was linked to a web application. The application 
                      combined computer vision, front-end, back-end and embedded systems code to provide security for an x-ray gate.</p>
                </div>
                <div className="exp-date text-md-right">
                    <span>June 2021 - June 2022</span>
                </div>
            </div>
          </div>
      </div>
    </div>
  </section>
);

export default About;