import React from "react";
import Container from 'react-bootstrap/Container';
import { Navbar, Nav } from "react-bootstrap";
import "../../App.css";

const NavBar = () => {
    return(
      <Navbar bg="dark" variant="dark" fixed="top" >
      <Container fluid>
        <Nav className="me-auto ">
          <Nav.Link href="#home">Home</Nav.Link>
          <Nav.Link href="#about">About</Nav.Link>
          <Nav.Link href="#skills">Skills</Nav.Link>
          <Nav.Link href="#portfolio">Projects</Nav.Link>
          <Nav.Link href="#contact">Contact</Nav.Link>
        </Nav>
      </Container>
    </Navbar>
    );
  }

export default NavBar;