import React from "react";
import "../../App.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Header = () => (
  <header className="masthead" id="home">
      <div className="mastheadContent">
        <div className='row'>
          <div className="col-lg-6" id="name">
            <h1>
              ARTHUR
            </h1>
            <h1>
              KYUPELYAN
            </h1>
          </div>
          <div className="col-lg-6">            
            <p>
              <a href="mailto:akyupelyan@gmail.com" className="contactLinks"><FontAwesomeIcon icon="fa-solid fa-envelope" /></a>
            </p>
            <p>
              <a href="https://www.linkedin.com/in/arthur-kyupelyan-379b9b193/" className="contactLinks"><FontAwesomeIcon icon="fab fa-linkedin" /></a>
            </p>
            <p>
              <a href="https://github.com/akyupelyan" className="contactLinks"><FontAwesomeIcon icon="fab fa-github" /></a>
            </p>
          </div>
        </div>
        <div className="col-lg-12 mx-auto">
          <a className="btn btn-xl " href="#about" smooth="true">Find Out More</a>
        </div>
      </div>
  </header>

);

export default Header;