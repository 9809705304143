import React from "react";
import "../../App.css";

const Projects = () => {
  return (
    <section id="portfolio">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading">Projects</h2>
              <br/>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 vl project-name">
              <h3>Pokedex:</h3>
            </div>
            <div className="col-lg-8 project-desc">
              <p>
                A web application that pulls and displays pokemon data from a web api made using ReactJS.
              </p>
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col-lg-4 vl project-name">
              <h3>ChatApp:</h3>
            </div>
            <div className="col-lg-8 project-desc">
              <p>
                A web app that allows users to make accounts and add/chat with friends made using Javascript. This project involved web sockets for client/server communication.
              </p>
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col-lg-4 vl project-name">
              <h3>Recipes:</h3>
            </div>
            <div className="col-lg-8 project-desc">
              <p>
                A recipe sharing blog website that is connected to a MongoDB database made using HTML, CSS and Javascript.
              </p>
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col-lg-4 vl project-name">
              <h3>Autonomous RC Car:</h3>
            </div>
            <div className="col-lg-8 project-desc">
              <p>
              A fully autonomous RC car made using Raspberry Pi, Arduino, and Python. The car is able to detect and drive within lanes.
              </p>
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col-lg-4 vl project-name">
              <h3>Personal Website:</h3>
            </div>
            <div className="col-lg-8 project-desc">
              <p>
                This website made using HTML, CSS, and ReactJS.
              </p>
            </div>
          </div>
        </div>
      </section>
  );
};

export default Projects;