import React from "react";
import "../../App.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Marquee from "react-fast-marquee";

const Skills = () => {

    // const root = document.documentElement;
    // const marqueeElementsDisplayed = getComputedStyle(root).getPropertyValue("--marquee-elements-displayed");
    // const marqueeContent = document.querySelector("ul.marquee-content");

    // root.style.setProperty("--marquee-elements", marqueeContent.children.length);

    // for(let i=0; i<marqueeElementsDisplayed; i++) {
    //   marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true));
    // }
     
    return (
      <section id="skills">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="skills-heading">Skills</h2>
              <br/>
            </div>
          </div>
        </div>
        <div className="container">
          <Marquee gradient={false}>
            
              <FontAwesomeIcon className="marquee-content" icon="fab fa-github"/>
              <FontAwesomeIcon className="marquee-content" icon="fab fa-html5"/>
              <FontAwesomeIcon className="marquee-content" icon="fab fa-css3-alt"/>
              <FontAwesomeIcon className="marquee-content" icon="fab fa-js"/>
              <FontAwesomeIcon className="marquee-content" icon="fab fa-react"/>
              <FontAwesomeIcon className="marquee-content" icon="fab fa-angular"/>
              <FontAwesomeIcon className="marquee-content" icon="fab fa-vuejs"/>
              <FontAwesomeIcon className="marquee-content" icon="fab fa-node"/>
              <FontAwesomeIcon className="marquee-content" icon="fab fa-python"/>
              <FontAwesomeIcon className="marquee-content" icon="fab fa-raspberry-pi"/>
              <FontAwesomeIcon className="marquee-content" icon="fab fa-android"/>

          </Marquee>
        </div>
      </section>
      
    );
  };
  
  export default Skills;